import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import PrivateRoute from '../components/PrivateRoute';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { Spacer } from '../../utils/components/Spacer';
import { 
    StyledDiv, 
    StyledTypograpy, 
    StyledButton, 
    NewsDateTypograpy, 
    NewsModalBox, 
    MenuDiv, 
    MenuItemPaper, 
    MenuItemIcon,
    MenuItemTypography } from '../styles/style';

import SnackBar from '../../utils/components/SnackBar';

//img import
import dailyIcon from '../../assets/img/icon/icon-graph_large.svg';
import unitDownload from '../../assets/img/icon/icon-download_large.svg';
import ukeoiIcon from '../../assets/img/icon/icon-file_large-ukeoi.svg';
import meisaiIcon from '../../assets/img/icon/icon-file_large-meisai.svg';
import { toBlobPdf, toBlobZip, getCurrentUserToken } from '../../utils/functions';

axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});


export function Top() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const baseURL = process.env.REACT_APP_API_ENDPOINT;
    const [open, setOpen] = useState(false);
    // お知らせ情報格納
    const [res, setRes] = useState([]);
    
    const [infoId, setInfoId] = useState('');
    const [infoText, setInfoText] = useState('');
    const [infoTitle, setInfoTitle] = useState('');
    const [infoDate, setInfoDate] = useState('');
    const [infoAttach, setInfoAttach] = useState('');
    
    // ファイルダウンロード
    const [downloadStart, setDownloadStart] = useState<boolean>(false);
    const [downloadFailed, setDownloadFailed] = useState<boolean>(false);
    
    const auth = useAuth();
    const navigate = useNavigate();
    
    const handleOpen = (info_id: string,title: string, text: string, date: string, attach: string) => {
        setInfoId(info_id);
        setInfoTitle(title);
        setInfoText(text);
        setInfoDate(date);
        setInfoAttach(attach)
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
  
    // S3からファイル取得　
    const getInfoFile = async (event: any) => {
        event.preventDefault();
        const currentUserToken = await getCurrentUserToken();
        // axios.request({
        //     url: baseURL + "/login-after-pdf",
        //     method: "post",
        //     headers: {
        //         Authorization: `${currentUserToken}`,
        //     },
        //     data: {
        //         'information_id': infoId,
        //         'pdf_file_name': infoAttach
        //     }
        // })
        // .then((response) => {
        //     // pdfファイルかzipファイルかで処理を変更する
        //     const blob: Blob = infoAttach.endsWith('.pdf')? toBlobPdf(response.data, "application/pdf") : toBlobZip(response.data);
        //     const url = window.URL.createObjectURL(blob);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     console.log(url);
        //     link.setAttribute('download', infoAttach);
        //     document.body.appendChild(link);
        //     link.click();
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
        try{
            setDownloadStart(true);
            
            const response = await axios.request({
                url: baseURL + "/login-after-pdf",
                method: "post",
                headers: {
                    Authorization: `${currentUserToken}`,
                },
                data: {
                    'information_id': infoId,
                    'pdf_file_name': infoAttach
                }
            });
            
            const s3_url = response.data.url;

            // const contentResponse = await axios.get(s3_url, {
            //     responseType: 'blob',
            //     onDownloadProgress: progressEvent => {
            //         const total:any = progressEvent.total;
            //         const current = progressEvent.loaded;
            //         const percentCompleted = Math.round((current / total) * 100);
            //         // Update progress in your browser's download icon
            //         updateDownloadProgress(percentCompleted);
            //     }
            // });
            
            // const blob: Blob = infoAttach.endsWith('.pdf') ? contentResponse.data : toBlobZip(contentResponse.data);
            // const url = window.URL.createObjectURL(blob);
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', infoAttach);
            // document.body.appendChild(link);
            // link.click();
            
            // // Clean up
            // link.remove();
            // window.URL.revokeObjectURL(url);
            
            const content = await axios({
                method: 'get',
                url: s3_url,
                responseType: 'blob'
            });
            
            // const blob: Blob = infoAttach.endsWith('.pdf')? toBlobPdf(content.data, "application/pdf") : toBlobZip(content.data);
            const url = window.URL.createObjectURL(content.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', infoAttach);
            document.body.appendChild(link);
            link.click();
            
            setDownloadStart(false);
            
        } catch (error) {
            setDownloadFailed(true);
            console.log(error);
        }
    };
  
    // 画面初期処理
    useEffect(() => {
        // ログイン前のお知らせを取得する 
        const getData = async() => {
            try {
                const _ = await getInfo();
                
            }
                catch(error){
                console.log(error);
            }
        }
        getData();
        setTimeout(() =>{setIsLoading(false)}, 500);
    }, []);
    
    const getInfo = async () => {
        const currentUserToken = await getCurrentUserToken();
        const userId = typeof(auth.customerUserId) !== "undefined"? auth.customerUserId: auth.username;
        axios.request({
            url: baseURL + "/topinfo",
            method: "post",
            headers: {
                Accept: "application/json",
                Authorization: `${currentUserToken}`,
            },
            data: {
                'user_id': userId,
                'get_type': 'top'
        
            }
        })
        .then((response) => {
            for(var i=0;i<response.data.length; i++){
                let tmp = response.data[i]['publicate_period_start_datetime'];
                tmp = tmp.split(' ')[0];
                response.data[i]['publicate_period_start_datetime'] = tmp;
            }
            setRes(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    return (
        <PrivateRoute>
            <Spacer size={50} />
            {auth.userAuthority[0] === "1" ?
                <StyledDiv>
                    <div style={{display: "flex"}}>
                        <StyledTypograpy variant="h4">NEWS</StyledTypograpy>
                        <StyledButton
                            onClick={() => navigate({ pathname: '/user/information' })}
                            endIcon={<ArrowForwardIosIcon />}>過去のお知らせを見る</StyledButton>
                    </div>
                    
                    <StyledTypograpy>お知らせ</StyledTypograpy>
                    {
                        !isLoading ?
                        
                        <List sx={{ width: "100%" }}>
                        {res.map((item) => (
                            <ListItem sx={{ borderBottom: "1px solid #E0E0E0", display: "flex", alignItems: "center" }} >
                                <NewsDateTypograpy>{item['publicate_period_start_datetime']}</NewsDateTypograpy>
                                <Link 
                                    component="button" 
                                    underline="hover"
                                    sx={{ padding: "15px 0", fontWeight: 500, textAlign: "left", color: "#333333" }}
                                    onClick={() => handleOpen(
                                        item['information_id'],
                                        item['information_title'],
                                        item['information_text'],
                                        item['publicate_period_start_datetime'],
                                        item['information_attached_file']
                                    )}
                                >{item['information_title']}</Link>
                                <Dialog 
                                    open={open}
                                    onClose={handleClose}
                                    BackdropProps={{ style: { opacity: 0.5 }}}
                                    PaperProps={{ 
                                        style: {
                                            maxWidth: "910px", 
                                            width: "100%", 
                                            maxHeight: "720px", 
                                            height: "100%", }}}
                                >
                                    <DialogContent style={{ padding: "40px 80px" }}>
                                        <IconButton
                                            onClick={handleClose}
                                            style={{position: "absolute", top: 10, right: 10}}
                                            size="large"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        
                                        <NewsDateTypograpy>{infoDate}</NewsDateTypograpy>
                                        <Typography variant="h6" style={{ color: "#00506B", fontWeight: "bold" }}>{infoTitle}</Typography>
                                        
                                        <Spacer size={20} />
                                        
                                        {infoAttach &&
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Link component="button" underline="hover" onClick={getInfoFile} sx={{ color: "#333333"}}>{infoAttach}</Link>
                                                <FilterNoneOutlinedIcon sx={{ color: "#333333", fontSize: 15, marginLeft: "5px" }} />
                                            </div>
                                        }
                                        
                                        <Spacer size={20} />
                                        
                                        <NewsModalBox>
                                            <div style={{ wordWrap: "break-word", width: "100%", padding: "0 0 5px 0" }}>{infoText.split('\n').map((line) => 
                                                (<span>{line} <br/></span>)
                                            )}</div>
                                        </NewsModalBox>
                                    </DialogContent>
                                </Dialog>
                            </ListItem>
                        ))}
                        </List>
                        :
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                            <CircularProgress />
                        </div>
                    }
                    
                </StyledDiv> : <div />
            }
                
            {auth.userAuthority[0] === "1" ? <Spacer size={40} /> : <div />}
            
            <div style={{ backgroundColor: "#CCCCDB" }}>
                <MenuDiv>
                    <StyledTypograpy variant="h4">MENU</StyledTypograpy>
                    <StyledTypograpy>よく利用されるメニュー</StyledTypograpy>
                    
                    <Spacer size={20} />
                    
                    <Grid container spacing={2}>
                        {auth.userAuthority[4] === "1" ?
                            <Grid item xs={3}>
                                <MenuItemPaper onClick={() => navigate({ pathname: '/user/using-achievement/daily' })}>
                                    <div style={{ padding: "50px 30px" }}>
                                        <MenuItemIcon src={dailyIcon} alt="日別実績"/>
                                        <MenuItemTypography>日別実績</MenuItemTypography>
                                    </div>
                                </MenuItemPaper>
                            </Grid> : <div />
                        }
                        {auth.userAuthority[8] === "1" ?
                            <Grid item xs={3}>
                                <MenuItemPaper onClick={() => navigate({ pathname: '/user/using-achievement/download/monthly' })}>
                                    <div style={{ padding: "50px 30px" }}>
                                        <MenuItemIcon src={unitDownload} alt="月別30分単位実績ダウンロード"/>
                                        <MenuItemTypography>月別30分単位実績<br />ダウンロード</MenuItemTypography>
                                    </div>
                                </MenuItemPaper>
                            </Grid> : <div />
                        }
                        {auth.userAuthority[9] === "1" ?
                            <Grid item xs={3}>
                                <MenuItemPaper onClick={() => navigate({ pathname: '/user/billing-document/invoice' })}>
                                    <div style={{ padding: "50px 30px" }}>
                                        <MenuItemIcon src={ukeoiIcon} alt="請求書"/>
                                        <MenuItemTypography>請求書</MenuItemTypography>
                                    </div>
                                </MenuItemPaper>
                            </Grid> : <div />
                        }
                        {auth.userAuthority[10] === "1" ?
                            <Grid item xs={3}>
                                <MenuItemPaper onClick={() => navigate({ pathname: '/user/billing-document/specification' })}>
                                    <div style={{ padding: "50px 30px" }}>
                                        <MenuItemIcon src={meisaiIcon} alt="明細書"/>
                                        <MenuItemTypography>明細書</MenuItemTypography>
                                    </div>
                                </MenuItemPaper>
                            </Grid> : <div />
                        }
                    </Grid>
                </MenuDiv>
            </div>
            <SnackBar
                successMessage="ダウンロードを開始します"
                openSuccess={downloadStart}
                setOpenSuccess={setDownloadStart}
                failedMessage="ダウンロードに失敗しました"
                openFailed={downloadFailed}
                setOpenFailed={setDownloadFailed}/>
        </PrivateRoute>
    )
}
